<template>
  <!-- request finished -->
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="ALL PROJECTS"
          :breadcrumb="[
            {
              label: 'Projects',
           
            },
            { label: 'All Projects' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <div class="row">
                  <h4><b style="color: #00364f; font-weight: bolder">All Projects</b></h4>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr
                          class="text-white table-wrapper-scroll-y rounded-circle"
                          style="background-color: #00364f"
                        >
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate" style="font-size: 0.9vw; width: 10%">
                            Deu Date
                          </th>
                          <th class="text-truncate">Name</th>
                          <th class="text-truncate">Business Name</th>
                          <th class="text-truncate">Project</th>
                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody>
                          <!-- v-for="(state, index) in allstates" :key="index" -->
                          <tr
                            style="color: #00364f"
                            v-for="(service, index) in services"
                            :key="index"
                          >
                            <td class="text-truncate">{{ service.id }}</td>
                            <td class="text-truncate">{{ service.date }}</td>
                            <td class="text-truncate">{{ service.dueDate }}</td>
                            <td class="text-truncate">
                              {{ service.client.fname }} {{ service.client.lname }}
                            </td>
                            <td class="text-truncate">{{ service.client.email }}</td>
                            <td class="text-truncate">{{ service.service.name }}</td>

                            <td class="text-truncate">
                              <p
                                v-if="service.status == 'assigned'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: #349c9e;
                                  color: white;
                                  letter-spacing: 2px;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                              <p
                                v-if="service.status == 'completed'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: #3ab04b;
                                  color: #fff;
                                  letter-spacing: 2px;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                              <p
                                v-if="service.status == 'pending'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: blue;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>New</b>
                              </p>
                              <p
                                v-if="service.status == 'processed'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: #ffaa00;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                              <p
                                v-if="service.status == 'hold'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: #8f8d8e;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                              <p
                                v-if="service.status == 'rejected'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                              <p
                                v-if="service.status == 'cancelled'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: #f50303;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                              <p
                                v-if="service.status == 'upgrade'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: #ee0def;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ service.status }}</b>
                              </p>
                            </td>
                            <td class="text-truncate">
                              <div class="btn-group btn-group-sm" role="group">
                                <router-link
                                  :to="`/staff/project-detailes/${service.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <router-link
                                  to="#"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: green;
                                  "
                                >
                                  <font-awesome-icon icon="edit" />
                                </router-link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Profassional Profile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      services: [],
      loading: false,
    };
  },
  methods: {
    clientServicesLoad() {
      this.loading = true;
      this.$axios
        .get("staff/clientservice", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.services = res.data.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.clientServicesLoad();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
